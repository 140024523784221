<template>
	<v-dialog v-model="show_bid_box" persistent width="auto" fullscreen transition="slide-fade" light>
		<v-row no-gutters class="bid" style="background: rgba(0,0,0,0.40);">
			<v-col cols="6" class="col-lg-6 col-md-5 col-sm-3"></v-col>
			<v-col
				cols="6"
				style="height:100vh"
				class="bid-box pa-0 d-flex flex-column col-lg-6 col-md-7 col-sm-9"
			>
				<div class="px-6 pb-6 end-shadow" style="position:reletive">
					<v-row
						no-gutters
						class="pl-6 d-flex align-center"
						style="height:79px; border-bottom:1px solid #E7EAF3; margin: 0px -24px 0px -24px;"
					>
						<v-btn v-on:click="show_bid_box = false" class="my-back-btn" depressed>
							<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
							Back
						</v-btn>
					</v-row>

					<app-bid-header v-bind:value="bidHeader()"></app-bid-header>
				</div>

				<div class="bid-items-content px-6">
					<div class="show-shadow col-lg-6 col-md-7 col-sm-9"></div>

					<app-list-interaction class="mb-6" v-if="bid_data.notes != null" v-model="bid_data.notes"></app-list-interaction>

					<v-row no-gutters class="display-time">
						<v-col cols="6" class="pa-0 d-flex align-center">
							<div>
								<p class="heading-rf13">Required delivery time:</p>
								<p v-if="bid_data.required_delivery_time === 'AS'" class="heading-rf15">As soon as possible</p>
								<p
									v-else
									class="heading-rf15"
								>{{ bid_data.required_delivery_date }} / {{ requiredDeliveryTime() }}</p>
							</div>
						</v-col>
						<v-col cols="6" class="pa-0 d-flex align-center">
							<div class="pl-6">
								<p class="heading-rf13">Declare delivery time:</p>
								<p class="heading-rf15">{{ bid_data.date_of_delivery }} / {{ timeOfDelivery() }}</p>
							</div>
							<div class="ml-auto">
								<img :src="$store.state.icons.icons['calendar']" style="height:16px;" alt />
							</div>
						</v-col>
					</v-row>

					<app-bid-items v-model="bid_data" :listitems="listitems"></app-bid-items>

					<app-show-bid-total v-model="calculateTotal"></app-show-bid-total>
				</div>

				<div class="bid-footer d-flex flex-column mt-auto mb-6">
					<div class="bid-footer-layer"></div>
					<div class="px-6 pt-6 d-flex">
						<p class="heading-sf15">Total:</p>
						<p class="heading-sf15 ml-auto">{{ this.$options.filters.currency(total) }}</p>
					</div>

					<div class="px-6 mt-auto">
						<v-btn
							class="my-outline-btn"
							id="my_elevation"
							depressed
							outlined
							block
							light
							@click="submitBid"
						>Cancel your bid</v-btn>
					</div>
				</div>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
const BidItems = () => import("./BidItemComponent");
const ShowBidTotal = () => import("./ShowBidTotalComponent");
const Feedback = () => import("./FeedbackComponent");
const ListInteraction = () =>
	import("../../mycomponents/ListInteractionComponent");
const BidHeader = () => import("../bidcomponent/BidHeader");

export default {
	components: {
		AppBidItems: BidItems,
		AppShowBidTotal: ShowBidTotal,
		AppFeedback: Feedback,
		AppListInteraction: ListInteraction,
		AppBidHeader: BidHeader
	},

	data() {
		return {
			bid_id: "",
			show_bid_box: false,
			bid_data: {
				check_bid: true,
				biditems: []
			},
			listitems: [],
			bid_total: {
				sub_total: 0.0,
				shipping: 0.0,
				service_fee: 0.0,
				sales_tax: 0.0
			}
		};
	},

	computed: {
		calculateTotal() {
			this.bid_total.sub_total = 0;
			this.bid_total.service_fee = 0;
			this.bid_total.sales_tax = this.bid_data.sales_tax;
			this.bid_total.shipping = this.bid_data.shipping;
			this.bid_data.biditems.forEach(item => {
				this.bid_total.sub_total +=
					Number(item.price) * Number(item.qty);
			});

			this.bid_total.service_fee =
				((this.bid_total.sub_total + this.bid_total.shipping) * 5) /
				100;

			this.bid_data.sub_total = this.bid_total.sub_total;
			this.bid_data.service_fee = this.bid_total.service_fee;
			return this.bid_total;
		},

		total() {
			let total = Number(
				this.bid_total.sub_total +
					this.bid_total.shipping +
					this.bid_total.service_fee +
					this.bid_total.sales_tax
			);
			return parseFloat(total).toFixed(2);
		}
	},

	methods: {
		checkBidSubmit(id) {
			this.$store.commit("errorMessage", "");
			this.show_bid_box = true;
			this.bid_id = id;
			if (this.list_id === "") {
				this.show_bid_box = false;
			}
			this.getBid();
		},

		bidHeader() {
			return {
				category_id: this.bid_data.category_id,
				list_id: this.bid_data.list_id,
				list_name: this.bid_data.list_name,
				// list_lat: this.bid_data.list_lat,
				// list_long: this.bid_data.list_long,
				// bid_lat: "",
				// bid_long: "",
				url: this.bid_data.url,
				created_at: this.bid_data.created_at,
				status: this.bid_data.status
			};
		},

		timeOfDelivery() {
			let st = this.bid_data.start_time;
			let et = this.bid_data.end_time;
			if (st != undefined && et != undefined) {
				return st.substring(0, 5) + " - " + et.substring(0, 5);
			}
		},

		requiredDeliveryTime() {
			let time = this.bid_data.required_delivery_time;

			if (time === "M") {
				return "Morning";
			} else if (time === "N") {
				return "Noon";
			} else if (time === "A") {
				return "Afternoon";
			} else if (time === "L") {
				return "Last Stop";
			} else if (time === "AS") {
				return "As soon as possible";
			}
		},

		async submitBid() {
			// let data = await cancelBid(this.bid_id);
			// let bid = {};
			// if (data.data.last_bid) {
			// 	bid = data.data.last_bid;
			// }
			// let data_ob = {
			// 	list: data.data,
			// 	bid: bid,
			// 	contractor: {},
			// 	notification: {}
			// };
			// this.$store.commit("listReset");
			// this.$store.commit("listData", data_ob);
			// if (data.message) {
			// 	this.show_bid_box = false;
			// }
		},

		async getBid() {
			let data;
			// if (this.$store.state.list.list_data.bid.bid_id != this.bid_id) {
			// 	let data_ob = await getBid(this.bid_id);
			// 	data_ob = {
			// 		list: data_ob.data.clist,
			// 		bid: data_ob.data,
			// 		contractor: {},
			// 		notification: {}
			// 	};
			// 	this.$store.commit("listReset");
			// 	this.$store.commit("listData", data_ob);
			// 	return JSON.parse(
			// 		JSON.stringify(this.$store.state.list.list_data)
			// 	);
			// } else {
			// 	data = JSON.parse(
			// 		JSON.stringify(this.$store.state.list.list_data)
			// 	);
			// }

			this.bid_data.list_id = data.list.list_id;
			this.bid_data.list_name = data.list.list_name;
			this.bid_data.category_id = data.list.category_id;
			this.bid_data.required_delivery_date = data.list.delivery_date;
			this.bid_data.required_delivery_time = data.list.delivery_time;
			this.bid_data.date_of_delivery = data.bid.date_of_delivery;
			this.bid_data.time_of_delivery = data.bid.time_of_delivery;
			this.bid_data.start_time = data.bid.start_time;
			this.bid_data.end_time = data.bid.end_time;
			this.bid_data.created_at = data.bid.created_at;
			this.bid_data.sub_total = data.bid.sub_total;
			this.bid_data.shipping = data.bid.shipping;
			this.bid_data.service_fee = data.bid.service_fee;
			this.bid_data.sales_tax = data.bid.sales_tax;
			this.bid_data.notes = data.list.notes;
			this.bid_data.biditems = data.bid.biditems;
			this.listitems = data.list.listitems;
			// this.bid_data.list_lat = data.list.latitude;
			// this.bid_data.list_long = data.list.longitude;
			this.bid_data.status = data.bid.status;
			this.bid_data.url = data.list.url;

			this.$store.commit("listReset");
		}
	}
};
</script>

<style lang="scss">
</style>